function parcours_formation() {
  function thisHeight(){
    return $(this).height();
  }
  var adjust_size = function() {
    $(".orgaRow").each(function() {
        var thisItemMax = Math.max.apply(Math, $(this).find(".orgaItem").map(thisHeight));
        $(this).height(thisItemMax + 44);
    });
  };
  adjust_size();

  $('.orgaItem.g4').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5').toggleClass('inactive');
  });
  $('.orgaItem.h5').hover(function() {
    $('.orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.d1, .orgaItem.c1, .orgaItem.b1').toggleClass('inactive');
  });
  $('.orgaItem.g5').hover(function() {
    $('.orgaItem.h5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.d1, .orgaItem.c1, .orgaItem.b1').toggleClass('inactive');
  });
  $('.orgaItem.f2').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.d1').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.c1').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.d2').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.d3').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.d4').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.c2').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.c3').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.c4').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.b1').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.b3').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.a3').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.b3, .orgaItem.a6, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.a6').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.b3, .orgaItem.a3, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.a8').hover(function() {
    $('.orgaItem.h5, .orgaItem.g5, .orgaItem.h4, .orgaItem.g4, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.b3, .orgaItem.a3, .orgaItem.a6').toggleClass('inactive');
  });
  $('.orgaItem.f5').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.d1, .orgaItem.c1, .orgaItem.b1').toggleClass('inactive');
  });
  $('.orgaItem.d5').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f6, .orgaItem.f5, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.c5').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f6, .orgaItem.f5, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.f6').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f5, .orgaItem.f7, .orgaItem.f8, .orgaItem.d1, .orgaItem.c1, .orgaItem.b1').toggleClass('inactive');
  });
  $('.orgaItem.d6').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f6, .orgaItem.f5, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.c6').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f6, .orgaItem.f5, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c7, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.f7').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f8, .orgaItem.d1, .orgaItem.c1, .orgaItem.b1').toggleClass('inactive');
  });
  $('.orgaItem.d7').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f6, .orgaItem.f5, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.c7').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f6, .orgaItem.f5, .orgaItem.f7, .orgaItem.f8, .orgaItem.e8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.b1, .orgaItem.a8').toggleClass('inactive');
  });
  $('.orgaItem.f8').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.d5, .orgaItem.d6, .orgaItem.d7, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.c5, .orgaItem.c6, .orgaItem.c7, .orgaItem.b1, .orgaItem.b3, .orgaItem.a3').toggleClass('inactive');
  });
  $('.orgaItem.e8').hover(function() {
    $('.orgaItem.h4, .orgaItem.h5, .orgaItem.g4, .orgaItem.g5, .orgaItem.f2, .orgaItem.f5, .orgaItem.f6, .orgaItem.f7, .orgaItem.f8, .orgaItem.d1, .orgaItem.d2, .orgaItem.d3, .orgaItem.d4, .orgaItem.c1, .orgaItem.c2, .orgaItem.c3, .orgaItem.c4, .orgaItem.b1').toggleClass('inactive');
  });
}
