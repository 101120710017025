;( function($, $window, $document) {
  'use strict';

  var $header         = $('#header'),
    $main             = $('#main'),
    $body             = $('body'),
    class_hidden      = 'l-header--hidden',
    class_fixed       = 'l-header--fixed',
    fixed_offset      = 20, // parseInt($main.css('margin-top')) / 2,
    throttle_timeout  = 500;

  if (!$header.length) return true;

  $header.addClass('l-header--stickyinit');

  var  w_height       = 0,
    w_scroll_current  = 0,
    w_scroll_before   = 0,
    w_scroll_diff     = 0,
    d_height          = 0,
    detection_margin  = null,

    throttle = function(delay, fn) {
      var last, defer_timer;
      return function() {
        var context = this, args = arguments, now = +new Date;
        if (last && now < last + delay) {
          clearTimeout(defer_timer);
          defer_timer = setTimeout(function(){
            last = now;
            fn.apply(context, args);
          }, delay);
        }
        else {
          last = now;
          fn.apply(context, args);
        }
      };
    };

  $window.on('scroll', function() {
    if ($body.hasClass('no-scroll')) return;

    d_height          = $document.height();
    w_height          = $window.height();
    w_scroll_current  = $window.scrollTop();
    w_scroll_diff     = w_scroll_before - w_scroll_current;
    detection_margin  = $header.height() / 2;

    $header.toggleClass(class_fixed, w_scroll_current > fixed_offset); // toggles "narrow" classname

    throttle(throttle_timeout, function() {
      if (w_scroll_current <= 0) { // scrolled to the very top; element sticks to the top
        $header.removeClass(class_hidden);
      }
      else if (w_scroll_diff > 0 && $header.hasClass(class_hidden)) { // scrolled up; element slides in
        $header.removeClass(class_hidden);
      }
      else if (w_scroll_diff < 0) { // scrolled down
        if (w_scroll_current +  w_height >= d_height && $header.hasClass(class_hidden)) { // scrolled to the very bottom; element slides in
          $header.removeClass(class_hidden);
        }
        else if (w_scroll_current > detection_margin) { // scrolled down; element slides out
          $header.addClass(class_hidden);
        }
      }
    })();

    w_scroll_before = w_scroll_current;
  });

  $window.trigger('scroll');

  // function scrollTo(hash) {
  //   if (!hash) return;

  //   var hash = hash.replace('#', '');
  //   if (!hash) return;

  //   var $element = $('#' + hash);
  //   if ($element.length == 0) return;

  //   if (typeof($element.css('scroll-margin-top')) != 'undefined') return;

  //   var scrollOffset = 0;

  //   if ($window.width() < 1000) {
  //     scrollOffset += 90 + 20;
  //   }
  //   else {
  //     scrollOffset += 119 + 20;
  //   }

  //   if ($('#wpadminbar').length > 0) {
  //     scrollOffset += 50;
  //   }

  //   $('html, body').animate({ scrollTop: $element.offset().top - scrollOffset }, 500, function() {
  //     $(this).scrollTop($element.offset().top - scrollOffset);
  //   });
  // }

  // if ($body.hasClass('browser-is-ie') || $body.hasClass('browser-is-safari')) {
  //   $(function() {
  //     $('a[href*="#"]').on('click', function(e) {
  //       var href = $(this).attr('href');
  //       if ( (this.hostname == location.hostname && this.pathname == location.pathname) || href.indexOf('#') === 0 ) {
  //         e.preventDefault();

  //         scrollTo(this.hash);
  //       }
  //     });
  //   })
  // }

  // $(function(){
  //   if (!location.hash) return;
  //   scrollTo(location.hash);
  // });

})(jQuery, $window, $document);
