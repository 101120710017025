// npm i leaflet --save
// npm i leaflet-gesture-handling --save
//= require leaflet/dist/leaflet-src.js
//= require leaflet-gesture-handling/dist/leaflet-gesture-handling.js
// @import "vendor/leaflet";
// @import "vendor/leaflet-gesture-handling";
// {% set mapid='map-' ~ random() %}

$(function() {
  var $map = $('#map');
  if ($map.length == 0) return;

  var center = [$map.data('lat'), $map.data('lng')];
  var map = L.map('map', {zoomControl: true, attributionControl: false, gestureHandling: true}).setView(center, 14);

  // 256
  // https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}@2x.png?key=xx

  // 512
  // https://api.maptiler.com/maps/basic/{z}/{x}/{y}@2x.png?key=xx

  // L.tileLayer('https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}@2x.png?key=xx', {
  //   attribution: '<a href=\"https://www.maptiler.com/\" target=\"_blank\">MapTiler</a>',
  //   maxZoom: 16,
  // }).addTo(map);

  // L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}@2x.png', {
  //   attribution: '<a href=\"https://wikimediafoundation.org/wiki/Maps_Terms_of_Use\" target=\"_blank\">Wikimedia</a>',
  //   maxZoom: 16,
  // }).addTo(map);

  L.tileLayer($map.data('layer'), {maxZoom: 16}).addTo(map);

  var marker = L.icon({
    iconUrl: $map.data('marker'),
    iconRetinaUrl: $map.data('marker-retina'),
    iconSize:    [25, 41],
    iconAnchor:  [12, 41]
  });

  L.marker(center, {icon: marker}).addTo(map);

  // var popup = L.popup({offset: [0, -30]});
  // popup.setLatLng([$this.data('lat'), $this.data('lng')])
  //   .setContent($this.html())
  //   .openOn(map);
  // L.marker(center, {icon: marker}).addTo(map).bindPopup('')

  // map.invalidateSize();
  // map.panTo(new L.LatLng($this.data('lat'), $this.data('lng')));

  // var group = new L.featureGroup([marker1, marker2, marker3]);
  // map.fitBounds(group.getBounds());
});

// https://github.com/Leaflet/Leaflet/issues/859
// L.Map.prototype.panToOffset = function(latlng, offset) {
//   var x = this.latLngToContainerPoint(latlng).x - offset[0];
//   var y = this.latLngToContainerPoint(latlng).y - offset[1];
//   var point = this.containerPointToLatLng([x, y]);
//   return this.setView(point, this._zoom);
// };
