$(function() {

  var $header = $('#header');

  var $links = $('.js-navigation-sub .js-navigation-link');

  $links.on('click', function(e) {
    if (!$header.hasClass('l-header--navigation-expanded')) {
      // mobile only
      return;
    }

    e.preventDefault();

    var $this = $(this);

    var $sub = $this.siblings('.l-navigation__sub');
    if ($sub.hasClass('l-navigation__sub--visible')) {
      $this.parent().removeClass('l-navigation__item--subexpanded');
      $sub.removeClass('l-navigation__sub--visible');
    }
    else {
      var $subs = $('.l-navigation__sub--visible').removeClass('l-navigation__sub--visible');
      var $links = $subs.siblings('.js-navigation-link');
      $links.parent().removeClass('l-navigation__item--subexpanded');

      $this.parent().addClass('l-navigation__item--subexpanded');
      $sub.addClass('l-navigation__sub--visible');
    }
  });

  var $sublinks = $('.js-navigation-side .js-navigation-sublink');

  $sublinks.on('click', function(e) {
    if (!$header.hasClass('l-header--navigation-expanded')) {
      // mobile only
      return;
    }

    e.preventDefault();

    var $this = $(this);

    var $side = $this.siblings('.l-navigation__side');
    if ($side.hasClass('l-navigation__side--visible')) {
      $this.parent().removeClass('l-navigation__subitem--sideexpanded');
      $side.removeClass('l-navigation__side--visible');
    }
    else {
      var $sides = $('.l-navigation__side--visible').removeClass('l-navigation__side--visible');
      var $sidelinks = $sides.siblings('.js-navigation-sublink');
      $sidelinks.parent().removeClass('l-navigation__subitem--sideexpanded');

      $this.parent().addClass('l-navigation__subitem--sideexpanded');
      $side.addClass('l-navigation__side--visible');
    }
  });

});
